import React, { Fragment } from 'react';
import Header from '../pages/Header/Header'; // Update the path if necessary
import Footer from '../pages/Footer/Footer'; // Update the path if necessary
import Routers from '../routers/Router';
import NewYearPopup from '../pages/Popup/newYearpopup';

// import Tak8popup from '../pages/Popup/Popup'
const Layouts = () => {
  return (
    <Fragment>
      <Header />
      <div>
        <Routers />
      </div>
      <NewYearPopup/>
      {/* <Tak8popup/> */}
      <Footer />
      {/* <ContactButton /> Add the ContactButton here */}
    </Fragment>
  );
};

export default Layouts;
