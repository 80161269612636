import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./newYearpopup.css";
import img from "../../assets/Imeges/PopUps/Update - Pop Up Img 3 - TAK8.jpg";

const NewYearPopup = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [timeLeft, setTimeLeft] = useState(null);
  const navigate = useNavigate(); // Initialize the navigate function

  // Set the offer to expire on January 20th at 11:59 PM
  const offerEndDate = new Date(2025, 0, 31, 23, 59, 59); // Month is 0-indexed (0 = January)

  const closePopup = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = offerEndDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);
        return { days, hours, minutes, seconds };
      }
      return null; // If the time has expired
    };

    // Update the countdown every second
    const timer = setInterval(() => {
      const remainingTime = calculateTimeLeft();
      if (remainingTime) {
        setTimeLeft(remainingTime);
      } else {
        clearInterval(timer); // Stop the timer when the countdown ends
        setTimeLeft(null);
      }
    }, 1000);

    // Clean up the timer when the component unmounts
    return () => clearInterval(timer);
  }, [offerEndDate]);

  const handleNavigate = () => {
    setIsVisible(false); // Close the popup
    navigate("/swift-car"); // Navigate to the specified route
  };

  return (
    isVisible && (
      <div className="popup-overlay">
        <div className="popup-container">
          <button className="popup-close" onClick={closePopup}>
            &times;
          </button>
          <div className="popup-content">
            <img src={img} alt="Suzuki Swift" className="popup-image" />
            <div className="popup-text">
              <h2 className="popup-title">🎄 New Year Special – Just $40/Day! 🎁</h2>
              <p className="popup-description">
                Drive the Suzuki Swift, people's favorite for just $40/day when
                you rent for 7 days or more this holiday season! 🎅✨
              </p>
              <hr />
              <div>
                <p className="countdown-content text-center">⏳ Hurry! Offer ends in:</p>
                <div className="countdown">
                  {timeLeft ? (
                    <p>
                      {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m{" "}
                      {timeLeft.seconds}s
                    </p>
                  ) : (
                    <p>🎉 Offer expired!</p>
                  )}
                </div>
              </div>
              <button className="popup-button" onClick={handleNavigate}>
                Grab the Deal Now!
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default NewYearPopup;
